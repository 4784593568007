import Web3 from 'web3'
import TronWeb from 'tronweb'
import store from '@/store'
import {
  checkSupported,
  getRpcMap,
  getChainName,
  getChainNetwork,
} from '@/web3/tools'
import {
  login,
  checkLogin,
  switchNetwork as switchNetworkAPI,
} from '@/api/account'
import {
  localSet,
  localGet,
  localClear,
  localGetJSON,
  parseToken,
  updateToken,
  loadToken,
} from '@/utils/common'
import Web3Connect from '@/utils/wallet'
import Socket from '@/utils/websocket/socket'
import PromiEvent from '@/utils/promiEvent'
import eventEmitter from './eventEmitter'
import router from '@/router'
import Toast from '@/utils/toast'
import i18n from '@/i18n/i18n'

const connectConfig = store.state._config.connectConfig

window.web3Connect = null // web连接

let SUBSCRIBE_WS = null // ws订阅连接

// 自动化连接
export async function checkConnectStatus() {
  const connectType = localGetJSON('web3ConnectType')
  if (!connectType) return false

  const connected = localGet('connected')
  if (connected == 'true') {
    await connectWallet(connectType)
  }
}

// 连接钱包
export async function connectWallet(connectType) {
  console.log('connectWallet', connectType)
  return new Promise(async (resolve, reject) => {
    try {
      store.commit('_info/Update_ConnectedLoading', true)
      const { chainId, network, wallet } = connectType
      const { connectInfo } = store.state._info
      const addressType = getChainNetwork(chainId)
      if (connectInfo.connected) {
        if (
          addressType === connectInfo.network &&
          connectInfo.wallet === wallet
        ) {
          // 如果是ETH系的直接切换
          if (chainId == connectInfo.chainId) {
            store.commit('_info/Update_ConnectedLoading', false)
            eventEmitter.emit('LOGIN_SUCCESS')
            return resolve([connectInfo.account, chainId])
          } else if (addressType === 'ETH') {
            // ETH可以直接换
            await switchNetwork(chainId)
            store.commit('_info/Update_ConnectedLoading', false)
            return resolve([connectInfo.account, chainId])
          }
        }
      }

      let options = {}
      if (wallet === 'WalletConnect') {
        if (network === 'ETH') {
          // 所有链信息
          const optionalChains = store.state._config.allNetwork
            .filter((item) => item.addressType === 'ETH')
            .map((item) => parseInt(item.chainId, 10))
          options = {
            projectId: connectConfig.projectId,
            showQrModal: true,
            chains: [Number(chainId)], // REQUIRED chain ids
            relayUrl: 'wss://relay.walletconnect.org',
            rpcMap: getRpcMap('ETH'),
            optionalChains,
            methods: [
              'eth_sendTransaction',
              'eth_signTransaction',
              'eth_sign',
              'personal_sign',
              'eth_signTypedData',
            ],
            events: ['chainChanged', 'accountsChanged', 'disconnect'],
            qrModalOptions: {
              themeVariables: {
                '--w3m-z-index': 5000,
              },
            },
          }
        } else {
          options = {
            network: chainId == '0x94a9059e' ? 'Shasta' : 'Mainnet',
            tronApiKey: connectConfig.tronApikey,
            options: {
              relayUrl: 'wss://relay.walletconnect.com',
              projectId: connectConfig.projectId,
            },
            web3ModalConfig: {
              themeVariables: {
                '--w3m-z-index': 5000,
              },
            },
          }
        }
      }
      const connector = new Web3Connect({
        ...connectType,
        options,
      })
      console.log('connector', connector)
      await connector.connect()
      const accountInfo = connector?.adapter?.accountInfo
      console.log('accountInfo', accountInfo)
      if (accountInfo && accountInfo.account) {
        const { account, chainId } = accountInfo
        // 更新连接信息
        localSet('connected', 'true')
        web3Connect = connector
        store.commit('_info/Update_ConnectInfo', {
          connected: true,
          supported: true,
          chainId: `${chainId}`,
          account,
          wallet,
          network,
        })
        // 订阅
        await subscribeProvider()
        localSet('web3ConnectType', JSON.stringify(connectType))
        store.commit('_info/Update_ConnectedLoading', false)
        // 是否是邮箱登录
        const loginType = localGet('loginType')
        if (loginType !== 'email') {
          // 登录信息
          const res = await loginChain(connector)
          console.log('loginChain', res)
          eventEmitter.emit(res === false ? 'LOGIN_INIT' : 'LOGIN_SUCCESS')
        }
        resolve([account, chainId])
      } else {
        throw new Error('accountInfo is null')
      }
    } catch (error) {
      console.log('connect', error)
      store.commit('_info/Update_ConnectedLoading', false)
      reject(error)
      if (!web3Connect) {
        disconnect()
      }
    }
  })
}

async function loginChain(connector) {
  try {
    const { chainId, account } = connector.adapter.accountInfo
    const supported = checkSupported(chainId)
    if (supported) {
      const token = parseToken()
      if (token && token.address === account && token.chainId === chainId) {
        return true
      }
      const canLoginRes = await handleCheckIsRegister({
        chainId,
        account,
      })
      if (canLoginRes) {
        await loginHandle(connector)
      } else {
        // 返回账户设置页
        const routeName =
          router.currentRoute.value.name === 'ContractPaymentStep'
            ? 'ContractPaymentStep'
            : 'QrCodePaymentStep'
        router.replace({
          name: routeName,
          params: {
            step: 0,
          },
        })
        clearLoginCache()
        return false
      }
    }
  } catch (error) {
    throw error
  }
}

// 检查是否是支持的链
export async function checkChain(chainId) {
  try {
    if (!web3Connect || !web3Connect.adapter || !loadToken()) {
      return
    }
    chainId = `${chainId}`
    const supported = checkSupported(chainId)
    const loginType = {
      chainId,
      wallet: web3Connect.wallet,
      network: web3Connect.network,
    }
    web3Connect.chainId = chainId
    web3Connect.adapter.chainId = chainId
    store.commit('_info/Update_ConnectInfo', loginType)
    // 是否是邮箱登录
    const type = localGet('loginType')
    if (supported && type !== 'email') {
      // 回调方法
      const callback = (flag = false) => {
        store.commit('_info/Update_ConnectInfo', { supported })
        // 重置余额
        store.commit('_info/Update_merchantBalance', null)
        localSet('web3ConnectType', JSON.stringify(loginType))
        eventEmitter.emit(flag ? 'LOGIN_SUCCESS' : 'LOGIN_INIT')
      }
      const { account = '' } = web3Connect?.adapter?.accountInfo
      if (account) {
        const canLoginRes = await handleCheckIsRegister({
          chainId,
          account,
        })
        if (canLoginRes) {
          await switchNetworkAPI({
            chainId,
            userAddress: account,
          }).then((res) => {
            if (res.check() && res.data) {
              updateToken(res.data)
              initWs() // 切换成功重新连接ws
              callback(true)
            } else {
              callback()
            }
          })
        } else {
          const routeName =
            router.currentRoute.value.name === 'ContractPaymentStep'
              ? 'ContractPaymentStep'
              : 'QrCodePaymentStep'
          router.replace({
            name: routeName,
            params: {
              step: 0,
            },
          })
          clearLoginCache()
          callback()
        }
      } else {
        await loginHandle(web3Connect)
      }
    }
  } catch (error) {
    throw error
  }
}

// 监听provider事件
async function subscribeProvider() {
  if (!web3Connect) return
  web3Connect.on('accountsChanged', (accounts) => {
    const account = web3Connect.adapter?.accountInfo?.account || ''
    if (account && accounts[0] === account) {
      return
    }
    disconnect()
    return
  })
  web3Connect.on('chainChanged', (id) => {
    const chainId =
      web3Connect.network === 'ETH' ? Web3.utils.hexToNumber(id) : id
    console.log('chainChanged', chainId)
    if (web3Connect.lastChainId != chainId) {
      console.log('准备切换' + chainId)
      web3Connect.lastChainId = chainId
      checkChain(chainId)
    }
  })

  // 断开连接
  web3Connect.on('disconnect', () => {
    disconnect()
  })
}

export function disconnect(active = false) {
  // 清理连接信息
  store.commit('_info/Reset_ConnectInfo')
  // 是否是邮箱登录
  const loginType = localGet('loginType')
  if (loginType !== 'email') {
    logout(active)
  }
  if (web3Connect) {
    web3Connect.removeAllListeners()
    web3Connect?.disconnect?.()
  }
  localSet('connected', 'false')
  SUBSCRIBE_WS?.close?.()
  clearWalletConnectCache()
}

// 退出登录
// active - 是否主动退出登录
export function logout(active = false) {
  if (active) {
    router.replace({ name: 'Login' })
  } else {
    if (router.currentRoute.value.name !== 'Login') {
      router.replace({
        name: 'Login',
        query: {
          redirect: location.pathname + location.search,
        },
      })
    }
  }
  clearLoginCache()
}

// 退出登录清理数据
function clearLoginCache() {
  localClear('token')
  localClear('loginType')
  store.commit('_info/Reset_LoginInfo')
}

// 发送签名消息
export async function signMessage(connector = web3Connect) {
  try {
    const timestamp = parseInt(new Date().getTime() / 1000)
    const message = `${store.state._config.signatureStr} ${timestamp}`
    const { account, chainId } = connector.adapter?.accountInfo
    const signature = await connector?.signMessage(message, account)
    return {
      chainId,
      timestamp,
      signature,
      message,
      userAddress: account,
    }
  } catch (e) {
    throw e
  }
}

// 登录
async function loginHandle(connector) {
  try {
    const msg = await signMessage(connector)
    return login(msg).then((res) => {
      if (res.check()) {
        updateToken(res.data.accessToken)
        localSet('loginType', 'wallet')
        store.commit('_info/Update_merchantInfo', res.data.info)
        store.commit('_info/Update_ConnectedStatus', true)
        return res.data
      } else {
        throw new Error(res.message)
      }
    })
  } catch (e) {
    throw e
  }
}

// 获取代币余额
export async function getTokenBalance() {
  return web3Connect?.getTokenBalance(...arguments)
}

// 充值
export function transferToken(data) {
  const promiEvent = PromiEvent()
  web3Connect.transferToken(data, promiEvent)
  return promiEvent.eventEmitter
}

// 直接转账
export function transfer(data) {
  const promiEvent = PromiEvent()
  web3Connect.transfer(data, promiEvent)
  return promiEvent.eventEmitter
}

// 汇款
export function remitToken(data) {
  const promiEvent = PromiEvent()
  web3Connect.remitToken(data, promiEvent)
  return promiEvent.eventEmitter
}

// 提币
export function withdrawToken(data) {
  const promiEvent = PromiEvent()
  web3Connect.withdrawToken(data, promiEvent)
  return promiEvent.eventEmitter
}

// 部署合约
export function deployContract() {
  web3Connect.deployContract(...arguments)
}

// 部署代付合约
export function deployPayoutContract() {
  web3Connect.deployPayoutContract(...arguments)
}

// 烧毁合约
export function destructContract() {
  web3Connect.destructContract(...arguments)
}

// 地址格式是否正确
export function isAddress(address, network) {
  let isAddress = false
  if (network) {
    switch (network) {
      case 'ETH':
        isAddress = Web3.utils.isAddress(address)
        break
      case 'TRON':
        isAddress = TronWeb.utils.crypto.isAddressValid(address)
        break
      default:
        isAddress =
          Web3.utils.isAddress(address) ||
          TronWeb.utils.crypto.isAddressValid(address)
        break
    }
  } else {
    isAddress = web3Connect?.isValidAddress(address)
  }
  console.log('isAddress', isAddress)
  return isAddress
}

// 清除wc本地缓存
export function clearWalletConnectCache() {
  localStorage.removeItem('wc@2:core:0.3//keychain')
  localStorage.removeItem('WCM_VERSION')
  localStorage.removeItem('wc@2:universal_provider:/namespaces')
  localStorage.removeItem('wc@2:universal_provider:/optionalNamespaces')
  localStorage.removeItem('wc@2:core:0.3//pairing')
  localStorage.removeItem('wc@2:core:0.3//subscription')
  localStorage.removeItem('wc@2:core:0.3//expirer')
  localStorage.removeItem('wc@2:core:0.3//history')
  localStorage.removeItem('wc@2:client:0.3//proposal')
  localStorage.removeItem('wc@2:client:0.3//session')
  localStorage.removeItem('wc@2:ethereum_provider:/chainId')
}

// 获取代付钱包地址
export async function getPayoutWalletAddress() {
  return web3Connect?.getPayoutWalletAddress(...arguments)
}

// 设置代付钱包地址
export function setPayoutWalletAddress(data) {
  const promiEvent = PromiEvent()
  web3Connect.setPayoutWalletAddress(data, promiEvent)
  return promiEvent.eventEmitter
}

// 获取代付金额
export async function getPayoutLimit(data) {
  return web3Connect.getPayoutLimit(data)
}

//  设置自动代付授权金额
export function setPayoutLimit(data) {
  const promiEvent = PromiEvent()
  web3Connect.setPayoutLimit(data, promiEvent)
  return promiEvent.eventEmitter
}

// 是否需要已注册
export async function handleCheckIsRegister({ chainId, account }) {
  try {
    const canLoginRes = await checkLogin({
      chainId,
      userAddress: account,
    })
    if (canLoginRes.check()) {
      return canLoginRes.data ? true : false
    } else {
      return false
    }
  } catch (e) {
    throw e
  }
}

// 切换网络
export async function switchNetwork(chainId) {
  return web3Connect.switchNetwork(chainId).catch((e) => {
    console.log('switchNetwork error', e)
    if (e?.code !== 4001) {
      Toast.info(
        i18n.global.t('common.switchNetworkTip', [getChainName(chainId)])
      )
    }
    return false
  })
}

// 初始化ws
export function initWs() {
  SUBSCRIBE_WS?.close()
  SUBSCRIBE_WS = Socket.init()
}

// 代付授权/拒绝 msgType: approval授权 reject拒绝
export function confirmPendingOrder(data) {
  const promiEvent = PromiEvent()
  web3Connect.confirmPendingOrder(data, promiEvent)
  return promiEvent.eventEmitter
}
